html {
	background: #eeeeee;
	color: #333;
	font-family: Rubik, sans-serif;
	/* font-size: 18px; */
	font-size: 20px;
}
html, body {
	margin: 0; padding: 0;
}
body {
	/* padding-bottom: 10px; */
	display: flex;
	flex-direction: column;
	min-height: 100%;
	min-height: 100vh;
}
* {
	box-sizing: border-box;
}

#container {
	flex: 1
}

footer {
	text-align: center;
}
footer img {
	height: 1em;
}

p {
	margin-block-start: 1.5em;
	margin-block-end: 1.5em;
}

p.wide {
	margin-block-start: 2em;
	margin-block-end: 2em;
}

.GameLayout {
	text-align: center;
	max-width: 640px;
	margin: auto;
}

.Grid {
	display: inline-block;
	border: 2px solid #444;
	/* margin: 20px auto; */
	border-radius: 6px;
	overflow: hidden;
	min-width: max-content;
}
.cell {
	width: 48px;
	height: 48px;
	display: inline-block;
	font-size: 24px;
	/*
	outline: 4px solid red;
	outline-offset: -2px;
	*/
	text-align: center;
	line-height: 48px;
}

h1 {
	font-size: 48px;
}

a {
	color: hsl(234, 80%, 40%);
	text-decoration: none;
}

a:hover, a:focus {
	text-decoration: underline;
}

button {
	min-width: 90px;
	font-size: 24px;
	padding: 10px;
	text-transform: capitalize;
	margin: 0px 5px;
	border-radius: 6px;
	border: 1px solid #444;
	color: white;
	background: #777;
}

.answer {
	margin: 80px 0px;
}

.red {
	background: hsl(348, 100%, 36%);
	/* background: #FF4136; */
	color: white;
}
.pink {
	background: hsl(340, 100%, 83%);
	color: hsl(320, 100%, 8%);
}
.blue {
	background: navy;
	color: rgb(255, 255, 255);
}
.light-blue {
	background: hsl(189, 100%, 58%);
	color: hsl(240, 100%, 10%);
}
.green {
	background: hsl(115, 100%, 20%);
	color: white;
}
.gray {
	background: hsl(0, 0%, 65%);
	color: white;
}
.black {
	background: black;
	color: white;
}
.white {
	background: white;
	color: black;;
}
.purple {
	background: hsl(300, 100%, 23%);
	color: white;
}
.yellow {
	background: hsl(60, 100%, 57%);
	/* background: #FFDC00 */
	color: hsl(240, 100%, 10%);
}
.brown {
	background: hsl(29, 100%, 17%);
	color: white;
}
.orange {
	background: hsl(36, 100%, 52%);
	color: black;
}

/* game b */
.gameB .red { background: hsl(350, 80%, 52%) }
.gameB .blue { background: hsl(217, 83%, 45%) }
.gameB .green { background: hsl(128, 97%, 37%) }
.gameB .yellow { background: hsl(60, 87%, 63%) }

.vert-list {
	padding-left: 0;
	list-style: none;
	line-height: 1.5em;
}
.vert-list li {
	margin-bottom: 10px;
}
